/* .vlb-outer{
    width: 100%;
    height: auto;
}

.vlp-thumb{
    width: 100%;
    position: relative;
    background-color: rgb(241, 232, 232);
    height: auto;
}

.vlb-gallery{
    width: 100%;
    height: auto;
    display: flex;
    overflow-y: scroll;
}

.vlb-thumb img{
    max-width: 100%;
}

.vlb-gallery img{
    max-width: 33%;
}

.vlb-gallery-thumb{
    cursor: pointer;
}

.vlb-gallery::-webkit-scrollbar{
    display: none;
} */


.swiper {
    width: 100%;
    height: 100%;
  }
  
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
  
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  body {
    background: #000;
    color: #000;
  }
  
  .swiper {
    width: 100%;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .swiper-slide {
    background-size: cover;
    background-position: center;
  }
  
  .mySwiper2 {
    height: 80%;
    width: 100%;
  }
  
  .mySwiper {
    height: 20%;
    box-sizing: border-box;
    padding: 10px 0;
  }
  
  .mySwiper .swiper-slide {
    width: 25%;
    height: 100%;
    opacity: 0.4;
  }
  
  .mySwiper .swiper-slide-thumb-active {
    opacity: 1;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  